import React, { useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TokenRefresh, TokenVerify } from "../../../../../data/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Verify-Email/VerifyEmail.scss";
import {
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";

const VerifyEmail = () => {
  const { key } = useParams();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const email = localStorage.getItem("user_email");

  function handleTokenRefresh() {
    dispatch(TokenRefresh(email))
      .unwrap()
      .then(() => {
        navigate("/verify");
      })
      .catch((err) => {
        toast.error("Failed to refresh token. Please try again.");
      });
  }

  useEffect(() => {
    if (key) {
      dispatch(TokenVerify(key))
        .unwrap()
        .then(() => {
          localStorage.removeItem("user_email");
          setTimeout(() => navigate("/login"), 3000);
        })
        .catch(() => {
          toast.error(error || "Email verification failed. Try again.");
        });
    }
  }, [key, dispatch]);

  return (
    <div className="verify-email-page h-screen flex items-center justify-center">
      {loading ? (
        <p>Verifying your email... Please wait.</p>
      ) : error ? (
        <div className="flex flex-col justify-center gap-3 items-center">
          <FontAwesomeIcon icon={faXmarkCircle} color="red" size="4x" />
          Token is invalid or expired
          {email && <button
            className=" verify-btn px-6 py-4"
            onClick={handleTokenRefresh}
          >
            Request new token
          </button>}
          <p className="mt-5 text-lg">
            Go to <Link to="/login" className="text-[#7e57c2] text-lg no-underline">Login</Link>
          </p>
        </div>
      ) : (
        <div className="flex flex-col justify-center gap-3 items-center">
          <FontAwesomeIcon icon={faCheckCircle} color="green" size="4x" />
          Your email has been verified!
          <Link to="/login">
            <button className="verify-btn px-6 py-4">Proceed to login</button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
